<template>
    <div class="tech-warp">
       <erji-carousel></erji-carousel>
      <div class="ab-text">
            <div class="ab-title animate__bounceIn" >
                 <p>—— 科技创新 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                 <p>
                    AI大中台
                </p>
             </div>
        </div>
      <div class="yz-img-con">
         <img src="../../assets/images/tech01.png">
      </div>
    </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped >
.ab-text{
           margin:40px 0px;
           .ab-title{
               text-align: center;
               font-size: 30px;
               color:#0184d2;
           }
           .ab-desc{
               margin-top:10px;
               text-align: center;
               font-size: 14px;
               color: #656d78;
           }
       }
   .yz-img-con{
       width: 100%;
       height: 550px;
       padding-top:70px;
       background: url("../../assets/images/AI-back.png");
     img{
       width: auto;
       object-fit:none;
       display: block;
       margin: 0 auto;
     }
   }
</style>
